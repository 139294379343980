.topContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  gap: 16px;

  > h2 {
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 38px;
    color: #151B26;
    line-height: 45px;
    font-style: normal;
    text-transform: none;
    text-align: center;
    line-height: 1.2;
  }

  > span {
    background: #EEF3FF;
    border-radius: 6px 6px 6px 6px;

    font-family: Inter, Inter;
    font-weight: 400;
    font-size: 16px;
    color: #1E5EFF;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    padding: 8px 16px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;

    > h2 {
      font-size: 32px;
    }
  }

}

.carousel {
  position: relative;
  width: 100%;
  aspect-ratio: 160/87;
  // height: 70vh;
  overflow: hidden;
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #151B26;

  &:hover {
    background-color: #f7fafc;
  }

  &Left {
    composes: button;
    left: 16px;
  }

  &Right {
    composes: button;
    right: 16px;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block {
  position: absolute;
  height: 100%;
  // width: 80%;
  aspect-ratio: 940/636;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: white;
  font-size: 24px;
  font-weight: bold;
}


